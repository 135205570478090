<script setup>
const props = defineProps({
    menuIcon: {
        type: String,
        default: 'far fa-ellipsis-h-alt',
    },
    orientationClass: {
        type: String,
        default: 'dropdown-end',
    }
});
</script>

<template>
    <div class="flex flex-row justify-end">
        <div class="dropdown" :class="props.orientationClass">
            <div tabindex="0" role="button" class="text-xl text-accent px-2 py-1">
                <slot name="action">
                    <i :class="props.menuIcon"></i>
                </slot>
            </div>
            <ul tabindex="0" class="dropdown-content bg-white border menu rounded-2xl z-[1] w-64 p-2">
                <slot name="menu">
                    <li><a>Item 1</a></li>
                    <li><a>Item 2</a></li>
                </slot>
            </ul>
        </div>
    </div>
</template>
